import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import styled from "styled-components";
import TeamCard from "../comp-layouts/TeamCard";

const getData = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___currentdetails___year], order: DESC }
      filter: {
        frontmatter: {
          currentdetails: { level: { eq: "Hon" } }
          alumni: { eq: true }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            collection
          }
          frontmatter {
            title
            description
            coredetails {
              order
              position
            }
            alt
            image {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
            generate
            current
            currentdetails {
              initials
              institute
              level
              project
              year
            }
          }
        }
      }
    }
  }
`;

const CurrentStudents = () => {
  const data = useStaticQuery(getData);
  const current = data.allMarkdownRemark.edges;
  return (
    <>
      {current.map(({ node }) => (
        <>
          {node.frontmatter.generate && (
            <TeamCard
              name={node.frontmatter.title}
              picture={node.frontmatter.image.childImageSharp.gatsbyImageData}
              position={node.frontmatter.coredetails.position}
              generate={node.frontmatter.generate}
              link={node.fields.slug}
            />
          )}
          {!node.frontmatter.generate && (
            <>
              {node.frontmatter.image && (
                <TeamCard
                  name={node.frontmatter.title}
                  generate={node.frontmatter.generate}
                  picture={
                    node.frontmatter.image.childImageSharp.gatsbyImageData
                  }
                  initials={node.frontmatter.currentdetails.initials}
                  institute={node.frontmatter.currentdetails.institute}
                  level={node.frontmatter.currentdetails.level}
                  project={node.frontmatter.currentdetails.project}
                  year={node.frontmatter.currentdetails.year}
                />
              )}
              {!node.frontmatter.image && (
                <TeamCard
                  name={node.frontmatter.title}
                  generate={node.frontmatter.generate}
                  initials={node.frontmatter.currentdetails.initials}
                  institute={node.frontmatter.currentdetails.institute}
                  level={node.frontmatter.currentdetails.level}
                  project={node.frontmatter.currentdetails.project}
                  year={node.frontmatter.currentdetails.year}
                />
              )}
            </>
          )}
        </>
      ))}
    </>
  );
};

export default CurrentStudents;
