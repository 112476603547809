import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import styled from "styled-components";
import theme from "../theme/theme.json";
import Container from "../components/comp-layouts/Container";
import Owl from "../components/comp-layouts/Owl";
import Button from "../components/Button";
import AlumHons from "../components/site/AlumHons";
import AlumMasters from "../components/site/AlumMasters";
import AlumPHD from "../components/site/AlumPHD";
import AlumPostDoc from "../components/site/AlumPostDoc";

const Alumni = () => {
  const [showLevel, setShowLevel] = useState("Hon");
  return (
    <Layout>
      <SEO
        title="Alumni"
        description="The members of Adrift Lab are members for life. We thank and appreciate everyone who has been an active part of our lab, and always remember their contributions to our research and our culture."
        slug="/alumni"
      />
      <Container mt="60px" mb="20px">
        <Owl>
          <h1>Alumni</h1>
          <p>
            The members of Adrift Lab are members for life. We thank and
            appreciate everyone who has been an active part of our lab, and
            always remember their contributions to our research and our culture.
          </p>
          <ButtonContainer>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showLevel == "Hon" ? "outline" : "solid"}
              onClick={() => setShowLevel("Hon")}
            >
              Honours
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showLevel == "MsC" ? "outline" : "solid"}
              onClick={() => setShowLevel("MsC")}
            >
              Masters
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showLevel == "PhD" ? "outline" : "solid"}
              onClick={() => setShowLevel("PhD")}
            >
              PhD
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showLevel == "PostDoc" ? "outline" : "solid"}
              onClick={() => setShowLevel("PostDoc")}
            >
              PostDoc
            </Button>
          </ButtonContainer>
        </Owl>
      </Container>
      <Container width="1024px" mb="50px">
        <div style={{ height: 20 }} />
        {showLevel === "Hon" && (
          <CardGrid>
            <AlumHons />
          </CardGrid>
        )}
        {showLevel === "MsC" && (
          <CardGrid>
            <AlumMasters />
          </CardGrid>
        )}
        {showLevel === "PhD" && (
          <CardGrid>
            <AlumPHD />
          </CardGrid>
        )}
        {showLevel === "PostDoc" && (
          <CardGrid>
            <AlumPostDoc />
          </CardGrid>
        )}
      </Container>
    </Layout>
  );
};

export default Alumni;

const CardGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-rows: 1fr;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin-top: 0;
  }
`;
